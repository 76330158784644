import { get, post, delete0 } from '@/utils/request'

const imageApi = {
  imagePostApi: '/api/content/post/image',
  imageApi: '/api/content/image'
}

// 创建相册
export function createAlbum(jsonData) {
  return post(imageApi.imagePostApi + '/create', jsonData)
}

// 添加图片到相册
export function addAlbumImage(jsonData) {
  return post(imageApi.imagePostApi + '/add', jsonData)
}

// 获取用户图片稿件
export function getAlbumImage(page) {
  return get(imageApi.imagePostApi + '?page=' + page)
}

// 获取用户图片稿件中的图片
export function getImagePostItems(albumId, page) {
  return get(imageApi.imagePostApi + '/' + albumId + '?page=' + page)
}

// 获取用户相册列表
export function getAlbumImage1(page, userId) {
  return get(imageApi.imageApi + '/user?page=' + page + '&userId=' + userId)
}

export function getImages(page) {
  return get(imageApi.imageApi + '?page=' + page)
}

// 获取用户相册中的图片
export function getImageItems(albumId, page) {
  return get(imageApi.imageApi + '/' + albumId + '?page=' + page)
}
