<template>
  <el-container>
    <el-header height="220">
      <el-row style="margin-top: 10px">
        <el-button type="plain" icon="el-icon-plus" @click="createAlbumDiaglog = true">创建相册</el-button>
      </el-row>
    </el-header>
    <el-main>
      <el-table
        :data="dataList"
        style="width: 100%"
      >
        <el-table-column
          type="index"
        />
        <el-table-column
          prop="coverUrl"
          label="相册封面"
          width="90"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.coverUrl">
              <el-image
                lazy
                fit="cover"
                class="coverImg"
                :src="scope.row.coverUrl"
              />
            </div>
            <div v-else>
              <span class="el-icon-plus" />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createdAt"
          label="发布时间"
        />
        <el-table-column
          prop="albumName"
          label="相册名字"
          width="180"
        >
          <template slot-scope="scope">
            <router-link style="text-decoration-line: none" target="_blank" :to="`/image/${scope.row.albumId}`">
              <span>{{ scope.row.albumName }}</span>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="total"
          label="数量"
        />
        <el-table-column
          prop="scope"
          label="可见范围"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.scope === 1"
              size="mini"
            >本人可见</el-tag>
            <el-tag
              v-else-if="scope.row.scope === 2"
              size="mini"
              type="success"
            >所有人可见</el-tag>
            <el-tag
              v-else-if="scope.row.scope === 3"
              size="mini"
              type="warning"
            >VIP 可见</el-tag>
            <el-tag
              v-else
              size="mini"
              type="danger"
            >验证码可见</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-plus"
              @click="handleEdit(scope.$index, scope.row)"
            >添加图片</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :small="screenWidth <= 768"
        hide-on-single-page
        layout="prev, pager, next"
        :page-size="pageSize"
        :current-page="currentPage"
        :total="totalSize"
        @current-change="handleCurrentChange"
        @prev-click="handleCurrentChange"
        @next-click="handleCurrentChange"
      />
    </el-main>

    <!-- 创建相册对话框 -->
    <el-dialog
      append-to-body
      :visible.sync="createAlbumDiaglog"
      width="30%"
      center
    >
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>创建相册</span>
          <el-button style="float: right; padding: 3px 0" type="text" @click="onCreateAlbum">确定</el-button>
        </div>
        <div class="text item">
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="相册名">
              <el-input v-model="form.albumName" style="width: 70%; padding-right: 2px" placeholder="相册名不能超过 50 个字符" />
            </el-form-item>
            <el-form-item label="可见范围">
              <el-select v-model="form.scope" placeholder="选择可见范围">
                <el-option label="本人可见" value="1" />
                <el-option label="所有人可见" value="2" />
                <el-option label="VIP 可见" value="3" />
                <el-option label="验证码可见" value="4" />
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
    </el-dialog>
  </el-container>
</template>

<script>
import {
  createAlbum, getAlbumImage
} from '@/api/image'

export default {
  name: 'ImagePost',
  data() {
    return {
      // 屏幕宽度, 为了控制分页条的大小
      screenWidth: document.body.clientWidth,
      currentPage: 1,
      pageSize: 12,
      totalSize: 0,
      dataList: [],
      // **********************************************************************
      form: {
        albumName: null,
        scope: '1'
      },
      createAlbumDiaglog: false
    }
  },
  created() {
    document.title = '我的图片稿件'
    this.getData()
  },
  methods: {
    handleCurrentChange(pageNumber) {
      this.currentPage = pageNumber
      this.getData()
      // 回到顶部
      scrollTo(0, 0)
    },
    getData() {
      this.dataList = []
      getAlbumImage(this.currentPage).then(resp => {
        if (resp.code === 0) {
          console.log(resp.data)
          this.dataList = resp.data.list
          this.totalSize = resp.data.totalSize
        }
      })
    },
    handleEdit(index, row) {
      const path = '/post/image/edit/' + row.albumId
      this.$router.push(path)
    },
    onCreateAlbum() {
      this.createAlbumDiaglog = false
      createAlbum(this.form).then(res => {
        if (res.code === 0) {
          this.$message.info('合集已创建')
          this.$router.go(0)
        } else {
          this.$notify({
            title: '提示',
            message: res.msg,
            type: 'warning',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify({
          title: '提示',
          message: error.message,
          type: 'warning',
          duration: 3000
        })
      })
    }
  }
}
</script>

<style>
/*处于手机屏幕时*/
@media screen and (max-width: 768px) {
  .tit {
    font-weight: 600;
    font-size: 12px;
    height: 32px;
  }
  .time {
    font-size: 10px;
    color: #999;
  }
  .num {
    font-size: 9px;
    padding-top: 3px;
  }
  .bottom {
    margin-top: 2px;
    line-height: 7px;
  }
  .coverImg {
    height: 120px !important;
  }
}

.coverImg {
  width: 100%;
  height: 90px;
  display: block;
}
</style>
